@import "_config.scss";

html {
    font-size: 12px;
}

body {
}

div.header {
    background: #009CD9;
    background: #FFF;
}

div.container,
div.container-fluid {
    background: #fff;
}

div.App {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #009CD9;
    background: #FFF;
}

div.App.inFrame {
    background: transparent;
}


input[type=text] {
    padding: 9px 12px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.375rem;
}

div.logo img {
    width: 100%;
}

div.border-2 {
    border: 2px solid #000;
}

div.ChatItems .messages {
    max-height: 500px;
    overflow-y: auto;
}

div.ChatItems h5 {
    font-size: 12px;
}

div.ChatItems input {
    width: calc(100% - 70px);
    display: inline-block;
    margin-right: 10px;
}

div.ChatItems div.ann {
    border-bottom: 1px dashed #cecece;
}

div.ChatItems span.meta {
    color: #999;
}

.modal.fade.show {
    backdrop-filter: blur(5px);
}


div.w300px {
    width: 200px;
}

span.highlight {
    background: #e9ff79;
    padding: 5px;
}

div.radioSelect {
    margin-top: 10px;
    margin-bottom: 10px;
}

div.radioSelect input {
    margin-right: 5px;
    display: inline;
}

div.radioSelect label {
    display: inline;
}

div.videoContainer {
    padding-left: 0;
    padding-right: 0;
}


div.loginContainer {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 400px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 1000px;
}

div.loginContainer h4 {
    margin-top: 20px;
}

div.loginContainer div.regForm + h4 {
    border-top: 2px solid #009CD9;
    padding-top: 40px;
}

div.loginContainer div.regForm {
    padding-bottom: 20px;

}

div.LiveBrowser div.ChatItems {
    padding-top: 20px;

}